<template>
  <div class="page-layout">
    <div class="authentication-container" ref="authentication">
      <div class="div_desc">
        <i class="icon-joyo i_iconfont2">&#xe746;</i>
        <span class="desc">为了您的账号安全，距离上一次登录超过72小时后，需要您进行一次身份验证</span>
      </div>
      <div class="div_title">身份验证</div>

      <form class="form-wrapper">
        <div
          class="input-field border-bottom-1px"
        >
          <span class="color-waring">使用手机{{
            adminUid.account.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2")
          }}验证</span>
        </div>
        <div class="msg-field">
          <div
            class="input-field border-bottom-1px msg"
            :class="{ active: mobileFocus === 1 }"
          >
            <i class="iconfont icon-joyo">&#xe72a;</i>
            <cube-input
              class="cube-input-wrapper"
              @focus="focusInput(1)"
              @blur="blurInput"
              v-model="messageParams.value"
              :clearable="messageParams.clearable"
              :placeholder="messageParams.placeholder"
              :type="messageParams.type"
              :autocomplete="true"
            ></cube-input>
          </div>
          <div @click="sendCode()" :disabled="disabled" class="msg-code">{{ btn }}</div>
        </div>
      </form>
      <div class="login-btn">
        <cube-button
          :primary="true"
          @click="verifyLogin"
          :disabled="loginBtnDisable"
          class="button"
          >{{ loginBtnText }}
        </cube-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import { checkVcodeLogin, sendWebSingle } from '../../api/sign-in';

export default {
  name: 'authentication',
  data() {
    return {
      messageParams: {
        value: '',
        placeholder: '短信验证码',
        type: 'text',
        clearable: false
      },
      time: 0,
      disabled: false,
      btn: '获取短信验证码',
      mobileFocus: 0,
      showPassword: true,
      showMsgVerify: false,
      loginBtnDisable: false,
      adminUid: localStorage.getItem('USERNAME') ? JSON.parse(atob(localStorage.getItem('USERNAME'))) : null
    };
  },
  computed: {
    loginBtnText() {
      return this.loginBtnDisable ? '保存中' : '保存';
    },
    ...mapGetters(['orgAndPostList'])
  },
  // eslint-disable-next-line no-unused-vars
  watch: {
    'time': {
      handler(value) {
        if (value > 0) {
          this.disabled = true;
        } else {
          this.disabled = false;
        }
      }
    }
  },
  beforeDestroy() {
    this.timer();
  },
  methods: {
    focusInput(n) {
      this.mobileFocus = n;
    },
    blurInput() {
      this.mobileFocus = 0;
      setTimeout(() => {
        if (this.mobileFocus === 0) {
          window.scrollTo(0, 0);
        }
      }, 0);
    },
    // 发送验证码
    sendCode() {
      sendWebSingle(this.adminUid.account, 'LAST_LOGIN_OVER_TIME').then(res => {
        if (res.flag) {
          this.time = 60;
          this.disabled = true;
          this.timer();
        }
      });
    },
    // 验证码倒计时
    timer() {
      if (this.time > 0) {
        this.time--;
        this.btn = `${this.time}s`;
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.btn = '获取短信验证码';
        this.disabled = false;
      }
    },
    verifyLogin() {
      if (this.messageParams.value.trim() === '') {
        return this.$showToast('请输入短信验证码');
      } else if (this.messageParams.value.length < 6) {
        return this.$showToast('请输入六位数字验证码');
      }
      this.loginBtnDisable = true;
      this._login();
    },
    _login() {
      window.scrollTo(0, 0);
      checkVcodeLogin(this.adminUid.account, this.messageParams.value, 'LAST_LOGIN_OVER_TIME', this.adminUid.tenancyId, this.adminUid.deptId, this.adminUid.postId, this.adminUid.loginSource)
        .then(res => {
          this.loginBtnDisable = false;
          if (res.flag) {
            this.$showToast('登录成功');
            localStorage.setItem('USERNAME', btoa(JSON.stringify({
              loginSource: this.adminUid.loginSource
            })));
            this.setUserInfo(res.data);
            this.setToken(res.data.token);
            this.setOrgAndPostList(res.data.postRoleResList);
            this.$router.push({ name: 'home' });
          } else {
            this.$showToast(res.errorMsg);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(err => {
          this.messageParams.value = '';
          this.loginBtnDisable = false;
        });
    },
    ...mapMutations({
      setUserInfo: 'SET_USER_INFO',
      setToken: 'SET_TOKEN',
      setOrgAndPostList: 'SET_ORG_AND_POST_LIST'
    })
  },

  mounted() {
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      // 兼容 safari 的 100vh
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    } else if (/(Android)/i.test(navigator.userAgent)) {
      // 兼容安卓小键盘
      const height = localStorage.getItem('screenHeight') || window.innerHeight;
      this.$refs.authentication.style.height = `${height}px`;
      localStorage.setItem('screenHeight', height);
    }
  }
};
</script>

<style lang="scss" scoped>
.page-layout {
  height: 100%;
}

.authentication-container {
  height: 100%;
  background: #ffffff;
  .div_desc{
    padding-top: 10px;
    padding-bottom: 10px;
    display: inline-block;
    width: 100%;
    background-color:#FFFBE6;
    .i_iconfont2{
      float: left;
      color: #F7A000;
      height: 15px;
      line-height: 15px;
      font-size: 15px;
      margin-left: 10px;
      padding-right: 10px;
      text-align: left;
    }
    .desc{
      float: left;
      color: #F7A000;
      line-height: 1;
      font-size: 14px;
      text-align: left;
      width: calc(100% - 45px);
    }
  }
  .div_title{
    padding-top: 43px;
    font-size: 30px;
    color: #333;
    padding-left: 26px;
    padding-bottom: 61px;
    text-align: left;
  }
}

.form-wrapper {
  padding: 0 25px;

  .input-field {
    display: flex;
    align-items: center;
    padding: 11px 0 2px;
    animation: all 0.3s;
    .color-waring{
      font-size: 16px;
      color: #333;
      padding-bottom: 16px;
      text-align: left;
    }
    .icon-joyo {
      color: #aaaaaa;
    }

    &.active {
      .icon-joyo {
        color: $color-joyo;
      }
    }

    input {
      flex: 1;
      margin: 0 14px;
    }

    .icon-input {
      width: 17px;
      height: 17px;
    }

    .input-append {
      &.close {
        width: 15px;
        height: 15px;
      }
    }
  }

  .msg-field {
    position: relative;
    .msg-code {
      position: absolute;
      right: 0;
      top: 11px;
      line-height: 42px;
      display: block;
      height: 36px;
      cursor: pointer;
      font-size: 16px;
      color: #D81924;
    }
  }
}

.login-btn {
  margin: 60px 25px 0;

  .button {
    background: $color-joyo;
  }
}

.icon-eye {
  width: 19px;
  height: 14px;
  margin-left: 10px;
}
</style>
